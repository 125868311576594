<template>
  <div class="px-40 border border-EEEEEE purseBox py-60">
    <div class="py-30 flex justify-center">
      <div v-html="info"></div>
    </div>
  </div>
</template>

<script>
import req from "../../utils/req";
export default {
  data() {
    return {
      info: "",
      foottype: 1,
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        let foottype = this.$route.query.foottype;
        if (foottype) {
          this.foottype = foottype;
          this.getdata();
        }
      },
      deep: true,
    },
  },
  mounted() {
    let foottype = this.$route.query.foottype;
    if (foottype) {
      this.foottype = foottype;
      this.getdata();
    }
  },
  methods: {
    // 获取数据
    getdata() {
      let that = this;
      if (that.foottype == 1) {
        req
          .post("index/content", {
            type: 1,
          })
          .then((res) => {
            if (res.code != 200) {
              that.$message.error(res.msg);
              return;
            }
            res.data = res.data
              .replace(/<img/gi, '<img style="max-width:100%;height:auto;display:block" ')
              .replace(/<section/g, "<div")
              .replace(/\/section>/g, "div>");
            that.info = res.data;
          })
          .catch((err) => {
            console.log(err);
            that.$message.error(err.msg);
          });
      } else if (that.foottype == 2) {
        req
          .post("index/content", {
            type: 2,
          })
          .then((res) => {
            if (res.code != 200) {
              that.$message.error(res.msg);
              return;
            }
            res.data = res.data
              .replace(/<img/gi, '<img style="max-width:100%;height:auto;display:block" ')
              .replace(/<section/g, "<div")
              .replace(/\/section>/g, "div>");
            that.info = res.data;
          })
          .catch((err) => {
            console.log(err);
            that.$message.error(err.msg);
          });
      }else if(that.foottype == 9){
        req
        .post("index/setting", {})
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          res.data.customer_content = res.data.customer_content
            .replace(/<img/gi, '<img style="max-width:100%;height:auto;display:block" ')
            .replace(/<section/g, "<div")
            .replace(/\/section>/g, "div>");
          that.info = res.data.customer_content;
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
      } else {
        req
          .post("index/content", {
            type: that.foottype,
          })
          .then((res) => {
            if (res.code != 200) {
              that.$message.error(res.msg);
              return;
            }
            res.data = res.data
              .replace(/<img/gi, '<img style="max-width:100%;height:auto;display:block" ')
              .replace(/<section/g, "<div")
              .replace(/\/section>/g, "div>");
            that.info = res.data;
          })
          .catch((err) => {
            console.log(err);
            that.$message.error(err.msg);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.purseBox {
  height: 100%;
  box-sizing: border-box;
}
</style>
